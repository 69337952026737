
  import { defineComponent, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import FuzzySearch from 'fuzzy-search';
  import { Store } from 'vuex';
  import { Compliment } from '@/models';
  import ComplimentCard from '@/components/ComplimentCard.vue';
  import { StoreActions } from '@/store';

  export default defineComponent({
    name: 'Home',
    components: { ComplimentCard },
    setup() {
      const store = useStore();
      const state = computed(() => store.state);
      const searchQuery = ref('');

      function filterCompliments(compliments: Compliment[], _query: string) {
        const query = _query.trim();
        if (query) {
          return new FuzzySearch(compliments, ['compliment'], { sort: true }).search(query) as Compliment[];
        } else {
          compliments = compliments
            .map((a) => ({ sort: Math.random(), value: a }))
            .sort((a, b) => a.sort - b.sort)
            .map((a) => a.value);
          return compliments;
        }
      }

      return {
        state,
        searchQuery,
        filterCompliments
      };
    }
  });

