
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
import { StoreActions } from '@/store';

export default defineComponent({
  setup() {
    const store = useStore();
    store.dispatch(StoreActions.LoadCompliments);
  },
});
