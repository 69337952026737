
import { defineComponent } from "vue";
import type { Compliment } from "@/models";

export default defineComponent({
  name: "ComplimentCard",
  props: {
    id: {
      type: String,
      required: true
    },
    compliment: {
      type: String,
      required: true
    },
  },
  setup(compliment: Compliment) {
    return { compliment };
  }
});
