
import type { Compliment } from "@/models";
import { defineComponent, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const complimentId = route.params.id;
    const compliments = store.state.allCompliments as Compliment[];
    const compliment = compliments.filter(({ id }) => id === complimentId)[0] as Compliment;
    const responseMessage = reactive<any>({});
    responseMessage.error = false;
    async function onSubmit(e) {
      let v = e.target[0].value;
      const username = v.split(' ')[0];
      if(username==''){
        responseMessage.error = true;
        responseMessage.message= "Please enter a valid Twitter Username";
      }
      if(responseMessage.error===false){
        const res = await fetch("https://api.saysomethingnice.today/post", {
          method: "POST",
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: JSON.stringify({
            username:username,
            compliment: complimentId,
          }),
        }).then(response => response.json())
        .then(async function(res) {
          console.log(res);
            if (res.status == '200') {
              responseMessage.success= true;
              responseMessage.message = 'indeed';
            } else {
              responseMessage.error = true;
              responseMessage.message= "Please only send once [The Twitter API has limits] - "+res.message;
            }
        });

      }
    }
    return { compliment, onSubmit, responseMessage };
  },
  mounted() {
    // adds the transition for the page opacity
    const _this = this;
    setTimeout(() => (_this.$refs.modal as any).classList.add("showing"));
  },
});
