import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/compliments/${_ctx.compliment.id}`,
    class: "compliment-card"
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        innerHTML: _ctx.compliment.compliment
      }, null, 8, _hoisted_1)
    ], undefined, true),
    _: 1
  }, 8, ["to"]))
}